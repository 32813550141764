* {
	margin: 0;
	padding: 0;
	box-sizing: 0;
}
h1,
h2,
h3 {
	color: rgb(0, 0, 0);
}

body {
	font-family: 'Lato', sans-serif;
}

h3,
h4 {
	font-weight: 400;
	color: rgba(100, 100, 100, 100);
}

.App {
	transition: all 0.5s ease;
	padding-top: 90px;
}
.library-active {
	margin-left: 30%;
}

@import "./song";
@import "./player";
@import "./library";
@import "./nav";
