.song-container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 14%;
    border-radius: 50%;
    box-shadow: 10px 10px 20px #777777, -10px -10px 20px #ffffff;
  }
  h2 {
    padding: 1rem;
  }
  h3 {
    font-size: 1rem;
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .song-container {
    img {
      width: 45%;
    }
  }
}
